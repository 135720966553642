.ChatView {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
}
.Background {
  background-color: rgba(255, 235, 0, 1);
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: unset !important;
  max-height: unset !important;
  z-index: 1;
}
.Background img {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  max-width: unset !important;
  max-height: unset !important;
  position: absolute !important;
  top: 0;
  left: 0;
}
.Background img:global(.CrossFadeBottom) {
  transition: opacity 0s ease-in-out;
  opacity: 0;
}
.Background img:global(.CrossFadeBottom.FadeInit) {
  opacity: 1;
}
.Background img:global(.CrossFadeBottom.FadeIn) {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}
.ChatView:after {
  content: " ";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: env(safe-area-inset-bottom);
  background-color: rgba(255, 235, 0, 1);
}
.Messages {
  flex-grow: 2;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  z-index: 10;
}
.UserInputContainer {
  /* background-color: rgba(255, 235, 0, 1); */
  background-color: white;
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: auto;
  padding: 0.4rem;
  align-items: center;
  z-index: 10;
}
.UserInputContainer button {
  background: none;
  border: none;
  padding: 0;
  line-height: 0.1;
}
.UserInputContainer button:disabled svg {
  opacity: 0.25;
}
.UserInputContainer button svg {
  height: 30px;
}
.UserInputContainer .PhotoButton svg {
  height: 24px;
}
.UserInputContainer button svg path {
  fill: black;
}
.UserInputContainer button svg rect:global(.sky) {
  fill: rgba(255, 235, 0, 1);
}
.UserInputContainer button:disabled svg rect:global(.sky) {
  fill: transparent;
}
.UserTextInput {
  /* background-color: rgba(255, 235, 0, 1); */
  border: 1px solid black;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  margin: 0 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: "Barlow Condensed", "Barlow", sans-serif;
  font-size: 1.2rem;
}

.UserInputMethodsContainer {
  background-color: rgba(255, 235, 0, 1);
  display: block;
  z-index: 10;
}
.ImagePane {
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.ImagePane img {
  max-height: 150px;
  margin-right: 1rem;
}
.TypingIcon {
  margin-left: 1rem;
  clear: both;
}
.TypingIcon svg {
  float: left;
  width: 100px;
}
.LargeImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background: #333;
}
.LargeImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
