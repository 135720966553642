.KeyboardTheme {
  background-color: transparent;
}
.KeyboardTheme :global(.hg-button) {
  background-color: transparent;
  font-family: "Barlow", sans-serif;
  font-size: 1.2rem;
  /* background-color: rgba(255, 245, 0, 1); */
  /* border-bottom: 1px solid rgba(128, 100, 0, 0.5); */
  border: 1px solid black;
  color: black;
  box-shadow: none;
}
.KeyboardTheme .KeyboardButtonSpace {
  flex-grow: 10;
}
.KeyboardTheme .ActiveKeys {
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  background-color: black;
  color: rgba(255, 235, 0, 1);
}
.KeyboardTheme :global .hg-row:nth-child(2) .hg-button:first-child {
  margin-left: 4%;
}
.KeyboardTheme :global .hg-row:nth-child(2) .hg-button:last-child {
  margin-right: 4%;
}
