.Completion {
  background-color: rgba(255, 235, 0, 1);
}
.Cursor {
  display: inline-block;
  background: black;
  height: 1em;
  position: relative;
  width: 2px;
  top: 2px;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
