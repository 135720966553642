.SharePanel {
  margin-left: 1rem;
  margin-bottom: 2rem;
  clear: both;
  min-width: 1px;
  min-height: 1px;
}
.SharePanel a {
  display: inline-block;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 235, 0, 1);
  margin-right: 0.3rem;
  border-radius: 0.1rem;
  vertical-align: bottom;
}
.SharePanel a img {
  max-height: 100%;
}
