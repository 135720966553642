@import url(https://fonts.googleapis.com/css2?family=Anton&family=Barlow&family=Barlow+Condensed&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Anton&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Epilogue&display=swap"); */

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: rgba(255, 235, 0, 1);
}
#root {
  background-image: url("/images/achtergrond_blur_webpage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header_Header__3VoE2 {
  position: relative;
  background: rgba(255, 235, 0, 1);
  z-index: 10000;
  padding: 0 0.5rem;
}

.Header_Header__3VoE2 h1 {
  font-size: 1.3rem;
  text-align: center;
  font-weight: normal;
  margin: 0.4rem 0;
}

.Header_BackButton__3QHpn {
  position: absolute;
  font-size: 1rem;
  right: 0.4rem;
  top: 0.65rem;
  display: block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
}
.Header_BackButton__3QHpn svg {
  height: 1.1rem;
}
.Header_HeaderImage__1xdww {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: 1rem;
  top: 5px;
}

.Chat_ChatView__19m5U {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
}
.Chat_Background__-54O6 {
  background-color: rgba(255, 235, 0, 1);
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: unset !important;
  max-height: unset !important;
  z-index: 1;
}
.Chat_Background__-54O6 img {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  max-width: unset !important;
  max-height: unset !important;
  position: absolute !important;
  top: 0;
  left: 0;
}
.Chat_Background__-54O6 img.CrossFadeBottom {
  transition: opacity 0s ease-in-out;
  opacity: 0;
}
.Chat_Background__-54O6 img.CrossFadeBottom.FadeInit {
  opacity: 1;
}
.Chat_Background__-54O6 img.CrossFadeBottom.FadeIn {
  opacity: 1;
  transition: opacity 2s ease-in-out;
}
.Chat_ChatView__19m5U:after {
  content: " ";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: env(safe-area-inset-bottom);
  background-color: rgba(255, 235, 0, 1);
}
.Chat_Messages__3_Hb6 {
  flex-grow: 2;
  overflow-y: scroll;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  z-index: 10;
}
.Chat_UserInputContainer__1Bu6_ {
  /* background-color: rgba(255, 235, 0, 1); */
  background-color: white;
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-template-rows: auto;
  padding: 0.4rem;
  align-items: center;
  z-index: 10;
}
.Chat_UserInputContainer__1Bu6_ button {
  background: none;
  border: none;
  padding: 0;
  line-height: 0.1;
}
.Chat_UserInputContainer__1Bu6_ button:disabled svg {
  opacity: 0.25;
}
.Chat_UserInputContainer__1Bu6_ button svg {
  height: 30px;
}
.Chat_UserInputContainer__1Bu6_ .Chat_PhotoButton__CNVcJ svg {
  height: 24px;
}
.Chat_UserInputContainer__1Bu6_ button svg path {
  fill: black;
}
.Chat_UserInputContainer__1Bu6_ button svg rect.sky {
  fill: rgba(255, 235, 0, 1);
}
.Chat_UserInputContainer__1Bu6_ button:disabled svg rect.sky {
  fill: transparent;
}
.Chat_UserTextInput__2xXZ3 {
  /* background-color: rgba(255, 235, 0, 1); */
  border: 1px solid black;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  margin: 0 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: "Barlow Condensed", "Barlow", sans-serif;
  font-size: 1.2rem;
}

.Chat_UserInputMethodsContainer__xIDGD {
  background-color: rgba(255, 235, 0, 1);
  display: block;
  z-index: 10;
}
.Chat_ImagePane__211xV {
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.Chat_ImagePane__211xV img {
  max-height: 150px;
  margin-right: 1rem;
}
.Chat_TypingIcon__1cZyi {
  margin-left: 1rem;
  clear: both;
}
.Chat_TypingIcon__1cZyi svg {
  float: left;
  width: 100px;
}
.Chat_LargeImage__17ZHB {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background: #333;
}
.Chat_LargeImage__17ZHB img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.PredictiveKeyboard_KeyboardTheme__21w9p {
  background-color: transparent;
}
.PredictiveKeyboard_KeyboardTheme__21w9p .hg-button {
  background-color: transparent;
  font-family: "Barlow", sans-serif;
  font-size: 1.2rem;
  /* background-color: rgba(255, 245, 0, 1); */
  /* border-bottom: 1px solid rgba(128, 100, 0, 0.5); */
  border: 1px solid black;
  color: black;
  box-shadow: none;
}
.PredictiveKeyboard_KeyboardTheme__21w9p .PredictiveKeyboard_KeyboardButtonSpace__M_esM {
  flex-grow: 10;
}
.PredictiveKeyboard_KeyboardTheme__21w9p .PredictiveKeyboard_ActiveKeys__Y_e0H {
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  background-color: black;
  color: rgba(255, 235, 0, 1);
}
.PredictiveKeyboard_KeyboardTheme__21w9p .hg-row:nth-child(2) .hg-button:first-child {
  margin-left: 4%;
}
.PredictiveKeyboard_KeyboardTheme__21w9p .hg-row:nth-child(2) .hg-button:last-child {
  margin-right: 4%;
}

.Message_MessageContainer__2prOA {
  display: block;
  clear: both;
}
.Message_Message__2daZE {
  max-width: 80%;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.5rem;
  margin: 0.5rem;
  color: white;
}
.Message_Message__2daZE p {
  margin: 0;
  font-family: "Impact", "Anton", sans-serif;
  font-size: 3rem;
  line-height: 1em;
  text-shadow: 0.2rem 0.2rem 0.4rem #000;
}
.Message_Message__2daZE p a {
  color: inherit;
}
.Message_Message__2daZE img {
  max-height: 200px;
  max-width: 100%;
  display: block;
  position: relative;
  /* border-radius: 0.5rem; */
}
.Message_HasImage__1BJQY p {
  border-radius: 0.5rem;
  padding: 0.3rem;
  background-color: rgba(255, 235, 0, 1);
}
.Message_FromUser__PjD8M {
  /* background-color: rgb(50, 136, 248); */
  /* color: white; */
  float: right;
  text-align: right;
  color: rgba(255, 235, 0, 1);
}
.Message_FromBot__3lSFV {
  /* background-color: rgb(199, 199, 199); */
  float: left;
}
.Message_HasImage__1BJQY.Message_FromBot__3lSFV p {
  background-color: white;
}
.Message_Message__2daZE label img {
  border-radius: 50%;
  /* float: left; */
  display: inline-block;
  width: 34px;
  height: 34px;
}

.PredictiveText_Predictions__3VrpC {
  display: flex;
  justify-content: center;
  font-family: "Barlow Condensed", "Barlow", sans-serif;
  font-size: 1.2rem;
}
.PredictiveText_Word__2o85O {
  margin: 0.4rem 0.1rem 0.1rem 0.1rem;
  padding: 0.15rem 1rem;
  border-right: 1px solid black;
}
.PredictiveText_Word__2o85O:last-child {
  border-right: none;
}

.SharePanel_SharePanel__3crTz {
  margin-left: 1rem;
  margin-bottom: 2rem;
  clear: both;
  min-width: 1px;
  min-height: 1px;
}
.SharePanel_SharePanel__3crTz a {
  display: inline-block;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 235, 0, 1);
  margin-right: 0.3rem;
  border-radius: 0.1rem;
  vertical-align: bottom;
}
.SharePanel_SharePanel__3crTz a img {
  max-height: 100%;
}

.AutoCompleteInput_Completion__2w47K {
  background-color: rgba(255, 235, 0, 1);
}
.AutoCompleteInput_Cursor__1zlmo {
  display: inline-block;
  background: black;
  height: 1em;
  position: relative;
  width: 2px;
  top: 2px;
  -webkit-animation: AutoCompleteInput_blink__230IA 1s linear infinite;
          animation: AutoCompleteInput_blink__230IA 1s linear infinite;
}
@-webkit-keyframes AutoCompleteInput_blink__230IA {
  50% {
    opacity: 0;
  }
}
@keyframes AutoCompleteInput_blink__230IA {
  50% {
    opacity: 0;
  }
}

.Home_Home__3sYQR {
  background-color: rgba(255, 235, 0, 1);
  height: 100%;
  overflow: scroll;
}
.Home_Home__3sYQR h1 {
  margin: 1rem 1rem;
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid #333;
}
.Home_Home__3sYQR ul {
  list-style: none;
  padding: 0 1rem;
}
.Home_Home__3sYQR a {
  text-decoration: none;
}
.Home_BotInfo__1PqjK {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-right: 2rem;
}
.Home_Avatar__351ze {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 1rem;
  margin-right: 1rem;
  background-color: #ccc;
}
.Home_Avatar__351ze img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
li:first-child .Home_BotInfo__1PqjK .Home_BotName__1KbxA {
  border-top: none;
}
.Home_BotInfo__1PqjK .Home_BotName__1KbxA {
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  padding-top: 1rem;
  border-top: 1px solid #444;
}
.Home_BotInfo__1PqjK .Home_BotName__1KbxA h3 {
  font-family: "Impact", "Anton", sans-serif;
  font-size: 2rem;
  line-height: 1em;
  color: white;
  text-shadow: 0.2rem 0.2rem 0.4rem #000;
  margin: 0;
}
.Home_BotInfo__1PqjK.Home_Inactive__uFN2x .Home_Avatar__351ze img {
  opacity: 0.2;
}
.Home_BotInfo__1PqjK.Home_Inactive__uFN2x h3 {
  text-shadow: none;
  color: #444;
}
.Home_BotInfo__1PqjK.Home_Inactive__uFN2x .Home_BotName__1KbxA {
  opacity: 0.3;
  color: #444;
}

.App_AppWrapper__3MCtv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App_App__16ZpL {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 375px;
  max-height: 812px;
  box-shadow: 0 0 10rem 1rem #333;
}

