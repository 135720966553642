.MessageContainer {
  display: block;
  clear: both;
}
.Message {
  max-width: 80%;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.5rem;
  margin: 0.5rem;
  color: white;
}
.Message p {
  margin: 0;
  font-family: "Impact", "Anton", sans-serif;
  font-size: 3rem;
  line-height: 1em;
  text-shadow: 0.2rem 0.2rem 0.4rem #000;
}
.Message p a {
  color: inherit;
}
.Message img {
  max-height: 200px;
  max-width: 100%;
  display: block;
  position: relative;
  /* border-radius: 0.5rem; */
}
.HasImage p {
  border-radius: 0.5rem;
  padding: 0.3rem;
  background-color: rgba(255, 235, 0, 1);
}
.FromUser {
  /* background-color: rgb(50, 136, 248); */
  /* color: white; */
  float: right;
  text-align: right;
  color: rgba(255, 235, 0, 1);
}
.FromBot {
  /* background-color: rgb(199, 199, 199); */
  float: left;
}
.HasImage.FromBot p {
  background-color: white;
}
.Message label img {
  border-radius: 50%;
  /* float: left; */
  display: inline-block;
  width: 34px;
  height: 34px;
}
