.Predictions {
  display: flex;
  justify-content: center;
  font-family: "Barlow Condensed", "Barlow", sans-serif;
  font-size: 1.2rem;
}
.Word {
  margin: 0.4rem 0.1rem 0.1rem 0.1rem;
  padding: 0.15rem 1rem;
  border-right: 1px solid black;
}
.Word:last-child {
  border-right: none;
}
