.AppWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 375px;
  max-height: 812px;
  box-shadow: 0 0 10rem 1rem #333;
}
