/* @import url("https://fonts.googleapis.com/css2?family=Anton&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Epilogue&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Barlow&family=Barlow+Condensed&display=swap");

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: rgba(255, 235, 0, 1);
}
#root {
  background-image: url("/images/achtergrond_blur_webpage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
