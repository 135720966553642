.Header {
  position: relative;
  background: rgba(255, 235, 0, 1);
  z-index: 10000;
  padding: 0 0.5rem;
}

.Header h1 {
  font-size: 1.3rem;
  text-align: center;
  font-weight: normal;
  margin: 0.4rem 0;
}

.BackButton {
  position: absolute;
  font-size: 1rem;
  right: 0.4rem;
  top: 0.65rem;
  display: block;
  appearance: none;
  background: none;
  border: none;
}
.BackButton svg {
  height: 1.1rem;
}
.HeaderImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: 1rem;
  top: 5px;
}
