.Home {
  background-color: rgba(255, 235, 0, 1);
  height: 100%;
  overflow: scroll;
}
.Home h1 {
  margin: 1rem 1rem;
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid #333;
}
.Home ul {
  list-style: none;
  padding: 0 1rem;
}
.Home a {
  text-decoration: none;
}
.BotInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-right: 2rem;
}
.Avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 1rem;
  margin-right: 1rem;
  background-color: #ccc;
}
.Avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
li:first-child .BotInfo .BotName {
  border-top: none;
}
.BotInfo .BotName {
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  padding-top: 1rem;
  border-top: 1px solid #444;
}
.BotInfo .BotName h3 {
  font-family: "Impact", "Anton", sans-serif;
  font-size: 2rem;
  line-height: 1em;
  color: white;
  text-shadow: 0.2rem 0.2rem 0.4rem #000;
  margin: 0;
}
.BotInfo.Inactive .Avatar img {
  opacity: 0.2;
}
.BotInfo.Inactive h3 {
  text-shadow: none;
  color: #444;
}
.BotInfo.Inactive .BotName {
  opacity: 0.3;
  color: #444;
}
